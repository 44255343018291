import React from 'react'

import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons/index'

// TODO: Move CSS out of component
const ActionErrorView = ({ action, onClose, error }) => {
  return (
    <Modal>
      <h1 className='modal-header'>{action} Failed</h1>
      <div className='model-content' style={{margin: '3em 5em', textAlign: 'center'}}>
        <p>{ error }</p>
        <ActionButton txt='Close' onClick={onClose} />
      </div>
    </Modal>
  )
}

export default ActionErrorView
