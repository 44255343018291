const jwt = require('@credlock-x/jwt-node')
const API = require('./api')
const DEMO = require('Constants').DEMO

const baseURL = process.env.REACT_APP_SERVER + '/'

const billingRoles = [
  'billing.invoice.list',
  'billing.invoice.get',
  'billing.invoice.stop',
  'billing.invoice.markpaid'
]

const memberRoles = [
  'customers.customer.update',
  'memberships.member.list',
  'memberships.member.get',
  'memberships.member.log',
  'memberships.member.keys',
  'memberships.member.freeze'
]

const operatorRoles = [
  'operators.location.list',
  'operators.location.get',
  'operators.location.create'
]

const adminRoles = [
  'admins.admin.list',
  'admins.admin.get',
  'admins.admin.update',
  'admins.admin.create'
]

const productRoles = [
  'memberships.product.list',
  'memberships.product.get',
  'memberships.product.update',
  'memberships.product.create'
]

const promotionRoles = [
  'memberships.promotion.list',
  'memberships.promotion.get',
  'memberships.promotion.update',
  'memberships.promotion.create'
]

const ptRoles = [
  'pts.pt.admin'
]

const roles = {
  admin: memberRoles
    .concat(operatorRoles)
    .concat(billingRoles)
    .concat(adminRoles)
    .concat(productRoles)
    .concat(promotionRoles)
    .concat(ptRoles),
  support: memberRoles.concat(billingRoles)
}

const mockUsers = {
  cl3: {
    name: 'CL3',
    operator: '23e9e82b-c3dd-40b4-b78a-4c6b5b527707',
    permissions: roles.admin
  },
  support: {
    name: 'support',
    operator: '23e9e82b-c3dd-40b4-b78a-4c6b5b527707',
    permissions: roles.support
  }
}

const mockAuth = (username, password, success, error) => {
  const customKey = 'my-custom-key'
  if (password === 'cl3' && mockUsers[username]) {
    success({
      access_token: jwt.pack(mockUsers[username], customKey),
      token_type: 'jwt',
      user: {
        id: 'cl3',
        name: 'cl3',
        username: 'cl3'
      }
    })
  } else {
    error({ status: 404, message: 'Not found' }, 404)
  }
}

const findAdmins = (args, success, error) => {
  API.fetchWithError('login/admins', success, error, args)
}

const auth = (args, success, error) => {
  const url = baseURL + 'login'

  if (DEMO) {
    mockAuth(args.username, args.password, success, error)
  } else {
    API.post(url, success, args, error)
  }
}

const resetPassword = (args, success, error) => {
  const url = baseURL + 'auth/reset'

  if (DEMO) {
    success({})
  } else {
    API.post(url, success, args, error)
  }
}

const updatePassword = (args, success, error) => {
  const url = baseURL + 'auth/update'

  if (DEMO) {
    success({})
  } else {
    API.post(url, success, args, error)
  }
}

module.exports = {
  auth: auth,
  findAdmins: findAdmins,
  resetPassword: resetPassword,
  updatePassword: updatePassword,
}
