import React, { Component } from 'react'

import { ActionButton } from 'components/Buttons/index'

import StopModal from '../StopModal'

class StopButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      modalIsOpen: false
    }

    this.openStopModal = this.openStopModal.bind(this)
    this.closeStopModal = this.closeStopModal.bind(this)
  }

  openStopModal () {
    this.setState({ stopModalIsOpen: true })
  }

  closeStopModal () {
    this.setState({ stopModalIsOpen: false })
  }

  render () {
    if (this.props.invoice.status !== 'declined' || !this.props.invoice.retry) {
      return null
    }
    if (this.state.stopModalIsOpen) {
      return (
        <StopModal
          invoice={this.props.invoice}
          onClose={this.closeStopModal}
        />
      )
    } else {
      return (
        <ActionButton
          txt='Stop'
          onClick={this.openStopModal}
          icon='error #FFA500'
        />
      )
    }
  }
}

export default StopButton
