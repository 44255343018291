import React from 'react'
import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'
import MembersAPI from 'services/api/members'
import { toast } from 'react-toastify'

const AnonymizeModal = ({ onClose, membership }) => {
  const submit = () => {
    MembersAPI.anonymizeMember({}, membership.operatorID, membership.id, () => {
      toast.success('Member successfully anonymized.')
      onClose()
    }, (error) => {
      toast.error(error.message)
      console.log('error', error)
      onClose()
    })
  }

  return (
    <Modal>
      <h1 className='modal-header'>Confirm Anonymization</h1>
      <div className='modal-content' style={{margin: '3em 5em'}}>
        <p style={{marginBottom: '2em', marginTop: '0'}}>Please confirm that you wish to anonymise the membership.
          This action is not reversible and identifying attributes of the
          customer will be anonymized.</p>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <ActionButton
            txt={'Cancel'}
            onClick={onClose}
          />
          <ActionButton
            txt={'Confirm'}
            onClick={submit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AnonymizeModal
