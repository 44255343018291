import React from 'react'
import AppContext from 'AppContext'

const LogBeta = (props) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }
  const membershipId = props.match.params.memberID
  const token = window.localStorage.getItem('auth-token')
  const { operator: {id} } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if(!id) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      <cl-membership-log
        membershipid={membershipId}
        apihost={`https://memberships.${env}.credlock.net`}
        token={token}
        timezone="Europe/Oslo"
      >
      </cl-membership-log>
    </>
  )
}

export default LogBeta
