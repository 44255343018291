import React from 'react'

import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'

// TODO: Move CSS out of component
const ActionSuccessView = ({ action, onClose }) => {
  return (
    <Modal>
      <h1 className='modal-header'>Success!</h1>
      <div className='model-content' style={{margin: '3em 5em', textAlign: 'center'}}>
        <p style={{marginBottom: '3em'}}>
          {action} registered.
        </p>
        <ActionButton txt='Close' onClick={onClose} />
      </div>
    </Modal>
  )
}

export default ActionSuccessView
