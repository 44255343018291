import React, { Component } from 'react'
import MarkPaidModalView from './MarkPaidModalView'
import ActionSuccessView from './ActionSuccessView'
import ActionErrorView from './ActionErrorView'
import { markInvoicePaid } from 'services/api/invoices'

class MarkPaidModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: null,
      error: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit (ev) {
    ev.preventDefault()

    const args = {
      reason: this.state.reasonPaid
    }

    // console.log('refund args', args)

    markInvoicePaid(args, this.props.invoice.membership_id, this.props.invoice.id, (res) => {
      // console.log(res)
      if (res.status === 'accepted') {
        this.setState({ status: 'success' })
      } else {
        this.setState({ status: 'error', error: 'Mark as paid failed' })
      }
    }, (err) => {
      console.log(err)
      this.setState({ status: 'error' })
    })
  }

  render () {
    let { status } = this.state

    if (status === 'success') {
      return (
        <ActionSuccessView
          action={'Mark Paid'}
          onClose={this.props.onClose}
        />
      )
    } else if (status === 'error') {
      return (
        <ActionErrorView
          action={'Mark Paid'}
          onClose={this.props.onClose}
          error={this.state.error}
        />
      )
    } else {
      return (
        <MarkPaidModalView
          invoice={this.props.invoice}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onClose={this.props.onClose}
          {...this.state}
        />
      )
    }
  }
}

export default MarkPaidModal
