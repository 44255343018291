import React from 'react'

import Modal from 'components/Modal'
import { ActionButton } from 'components/Buttons'

// TODO: Move CSS out of component
const StopModalView = ({ invoice, loading, onSubmit, onClose, onChange }) => {
  if (loading) {
    return null
  }

  return (
    <Modal>
      <h1 className='modal-header'>Stop Payment Attempts</h1>
      <div className='model-content' style={{margin: '3em 5em'}}>
        <form>
          <div style={{marginBottom: '3em'}}>
            <label style={{marginBottom: 0}}>Reasons for stoppage</label>
            <textarea
              name='reasonToStop'
              onChange={onChange}
              style={{width: '100%', height: '10em'}}
              placeholder='Explanation of stoppage'
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <ActionButton
              txt='Cancel'
              onClick={onClose}
            />
            <ActionButton
              txt={'Stop'}
              onClick={onSubmit}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default StopModalView
