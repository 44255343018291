import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import AuthAPI from 'services/api/auth'
import OperatorsAPI from 'services/api/operators'
import {
  LoginForm,
  Logo,
  ResetPassword,
  UpdatePassword,
} from './components'

export default class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      error: null,
      success: null,
      operators: [],
      operatorsToSelect: []
    }

    this.prepareOrDirectLogin = this.prepareOrDirectLogin.bind(this)
    this.prepareOrDirectReset = this.prepareOrDirectReset.bind(this)
    this.resetState = this.resetState.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    OperatorsAPI.fetchOperators((operators) => {
      this.setState({
        operators: operators,
        // Set operator to the found operator if only 1
        operator: operators.length === 1 ? operators[0].id : null
      })
    }, () => {
      this.setState({ error: 'System down', down: true })
    })
  }

  handleChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  resetState () {
    this.setState({ error: null, operatorsToSelect: [], success: null })
  }

  render () {
    return (
      <div className='login'>
        <Logo />
        <Router>
          <Switch>
            <Route exact path={'/'}>
              <LoginForm
                 onSubmit={this.prepareOrDirectLogin}
                 onChange={this.handleChange}
                 resetState={this.resetState}
                 {...this.state}
              />
            </Route>
            <Route path='/password/reset'>
              <ResetPassword
                onSubmit={this.prepareOrDirectReset}
                onChange={this.handleChange}
                resetState={this.resetState}
                { ...this.state }
              />
            </Route>
            <Route path={'/password/update'} component={UpdatePassword} />
            <Redirect from={'/'} to={'/'} />
          </Switch>
        </Router>
      </div>
    )
  }

  prepareOrDirectLogin (event) {
    event.preventDefault()

    // If we have set operator either from first fetch or by select box,
    // then just login
    if (this.state.operator) {
      return this._login()
    }

    const args = { username: this.state.username }

    AuthAPI.findAdmins(args, (res) => {
      // If we get an empty array then no user with given username was found
      if (res.length < 1) {
        return this.setState({ error: 'Wrong username and/or password' })
      }

      // If we only find one admin with given username,
      // then we just log the user in with the found operator id
      if (res.length === 1) {
        const operator = this.state.operators.find(o => o.id === res[0])
        this.setState({ operator: operator.id })
        return this._login()
      }

      // If we get here; then we have found more than one admin with
      // the given username across operators.
      // So we set the state for operatorsToSelect to found operator id's
      // (and reset errors)
      // This will prompt the Select box to be visible and the user must
      // then select an operator and click on Sign in button again
      this.setState({
        operatorsToSelect: this.state.operators.filter(o => res.includes(o.id)),
        error: null
      })
    }, () => {
      this.setState({ error: 'Something went wrong' })
    })
  }

  prepareOrDirectReset (event) {
    event.preventDefault()

    // If we have set operator either from first fetch or by select box,
    // then just login
    if (this.state.operator) {
      return this._resetPassword()
    }

    const args = { username: this.state.username }

    AuthAPI.findAdmins(args, (res) => {
      // If we get an empty array then no user with given username was found
      if (res.length < 1) {
        return this.setState({ error: 'Email not found' })
      }

      // If we only find one admin with given username,
      // then we just log the user in with the found operator id
      if (res.length === 1) {
        const operator = this.state.operators.find(o => o.id === res[0])
        this.setState({ operator: operator.id })
        return this._resetPassword()
      }

      // If we get here; then we have found more than one admin with
      // the given username across operators.
      // So we set the state for operatorsToSelect to found operator id's
      // (and reset errors)
      // This will prompt the Select box to be visible and the user must
      // then select an operator and click on Sign in button again
      this.setState({
        operatorsToSelect: this.state.operators.filter(o => res.includes(o.id)),
        error: null
      })
    }, () => {
      this.setState({ error: 'Something went wrong' })
    })
  }

  _resetPassword () {
    const args = {
      username: this.state.username,
      operator_id: this.state.operator,
      locale: 'en',
      url: `${window.location.origin}/password/update?operator=${this.state.operator}&token=`
    }

    AuthAPI.resetPassword(args, (res) => {
      this.setState({ success: 'done' })
    }, (error, status) => {
      console.log('status', status)
      console.log('error', error)
      this.setState({ error: 'Invalid token' })
    })
  }

  _login () {
    const args = {
      username: this.state.username,
      password: this.state.password,
      operator_id: this.state.operator
    }

    AuthAPI.auth(args, (res) => {
      const token = res.access_token
      const admin = res.user

      // TODO: Use a storage component?
      window.localStorage.setItem('auth-token', token)
      window.localStorage.setItem('currentAdmin', JSON.stringify(admin))
      this.props.login(token,  JSON.stringify(admin))
    }, (error, status) => {
      console.log('status', status)
      console.log('error in _login', error)
      this.setState({ error: 'Wrong username and/or password' })
    })
  }
}
