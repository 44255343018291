import React from 'react'
import AppContext from 'AppContext'

const KeyActivation = ({ownerid}) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }
  const token = window.localStorage.getItem('auth-token')
  const { operator } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if(!operator.id) {
    return (
      <div className='index sales-promotions'>
        <p>waiting for operator...</p>
      </div>
    )
  }

  return (
    <cl-user-key
      mountPath={`/operators/${operator.id}/administrators/${ownerid}`}
      apiUrl={`https://access-control.${env}.credlock.net/api`}
      operatorid={operator.id}
      token={token}
      ownerid={ownerid}
      >
    </cl-user-key>
  )
}

export default KeyActivation
